<template>
    <modal ref="ModalVerMas" :titulo="titulo" tamano="modal-lg" no-aceptar :cargado-modal="cargando">
        <div v-for="(producto,p) in productos" :key="p" class="row mx-0 mt-2 px-0 justify-center">
            <div class="col-8 mb-3">
                <p class="mb-0 f-15 f-600 text-general">
                    {{ producto.nombre }}
                </p>
                <div class="row">
                    <div class="col-auto f-14">
                        {{ separadorNumero(producto.utilidad_dinero) }} -
                        {{ agregarSeparadoresNumero(producto.utilidad_porcentaje,1) }}% Utilidad
                    </div>
                </div>
                <div class="row align-items-center" style="height:10px;">
                    <div class="col pr-0" style="height:10px;">
                        <div class="h-100 bg-gris br-5" :style="`width:${producto.porcentaje_max}%`" />
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Estadisiticas from "../../../services/estadisticas"

export default {
    props: {
        titulo: {
            type: String,
            default: () => ''
        }
    },
    data(){
        return{
            productos: [],
            params: {},
            cargando: false,
        }
    },
    methods: {
        toggle(params){
            /* console.log(params); */
            this.$refs.ModalVerMas.toggle()
            this.params = params
            this.ver_mas()
        },
        async ver_mas(){
            try {
                this.productos = []
                this.cargando = true

                const { data } = await Estadisiticas.ver_mas(this.params)
                console.log(data);
                this.productos = data

            } catch (error){
                console.log(error);
            } finally{
                this.cargando = false
            }
        },
    }
}
</script>
<style lang="scss" scoped>
</style>
